<template>
  <div ref="background" :style="deviceHeight" class="background-dialog">
    <div ref="dialog" class="dialog" :style="dialogWidth">
      <div class="container-dialog-title" :class="scrollClass">
        <h3 v-if="title">{{ title }}</h3>
        <v-icon class="icon-close" @click="closeDialog()" color="#03DAC5" size="25px">mdi-close</v-icon>
      </div>
      <div class="content-dialog">
        <slot></slot>
      </div>
      <div class="d-flex flex-row-reverse">
        <div class="pl-4 input-submit">
          <slot name="button-right"></slot>
        </div>
        <div class="input-submit">
          <slot name="button-left"></slot>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "myc-dialog",
  props: {
    title: String,
    width: Number,
    maxheight: {
      default: 80,
      type: Number,
    },
  },
  data() {
    return {
      scrollClass: "",
    };
  },
  mounted() {
    let scrollContainer = this.$refs.dialog;
    scrollContainer.addEventListener("scroll", (e) => {
      if (this.$refs.dialog.scrollTop > 10) {
        this.scrollClass = "background-dialog-scroll";
      } else {
        this.scrollClass = " ";
      }
    });
  },
  computed: {
    deviceHeight() {
      return "height:" + window.innerHeight + "px";
    },

    dialogWidth() {
      const width = this.width ? this.width + "px" : "auto";
      return "width:" + width + "; max-height: " + "calc(" + this.maxheight + "% - 32px)";
    },
  },
  methods: {
    closeDialog() {
      this.$emit("close-dialog");
    },
  },
};
</script>
